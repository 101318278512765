import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal']

  declare readonly modalTarget: HTMLDivElement

  open (): void {
    this.modalTarget.classList.remove('hidden')
  }

  close (): void {
    this.modalTarget.classList.add('hidden')
  }
}
