import { Controller } from '@hotwired/stimulus'

// Define the types for the targets to help TypeScript infer them
export default class extends Controller {
  static targets: string[] = ['riskCategory', 'riskType', 'riskSource', 'sourceCause']

  // Define the types for the controller targets
  declare readonly riskCategoryTarget: HTMLSelectElement
  declare readonly riskTypeTarget: HTMLSelectElement
  declare readonly riskSourceTarget: HTMLSelectElement
  declare readonly sourceCauseTarget: HTMLSelectElement
  declare readonly hasRiskCategoryTarget: boolean
  declare readonly hasRiskSourceTarget: boolean

  connect (): void {
    this.setupEventListeners()
  }

  setupEventListeners (): void {
    if (this.hasRiskCategoryTarget) { // Explicit check for truthiness
      this.riskCategoryTarget.addEventListener('change', (event) => {
        void this.updateRiskType(event) // Explicitly mark the promise as ignored
      })
    }
    if (this.hasRiskSourceTarget) { // Explicit check for truthiness
      this.riskSourceTarget.addEventListener('change', (event) => {
        void this.updateSourceCauses(event) // Explicitly mark the promise as ignored
      })
    }
  }

  // Method to update the risk type based on selected category
  async updateRiskType (event: Event): Promise<void> {
    const selectedCategoryId: string = this.riskCategoryTarget.value
    if (selectedCategoryId === '') return // Explicit empty string check

    try {
      const response: Response = await fetch(`/risks/risk_types_for_category/${selectedCategoryId}`)
      const data: { risk_types: Array<{ id: number, name: string }> } = await response.json()

      const riskTypeField = this.riskTypeTarget
      riskTypeField.value = ''

      if (data.risk_types?.length > 0) {
        riskTypeField.value = data.risk_types[0].name
        riskTypeField.disabled = true
      } else {
        riskTypeField.value = 'No risk type available'
        riskTypeField.disabled = true
      }
    } catch (error) {
      console.error('Error fetching risk types:', error)
    }
  }

  // Method to update the source causes based on selected source
  async updateSourceCauses (event: Event): Promise<void> {
    const selectedSourceId: string = this.riskSourceTarget.value
    if (selectedSourceId === '') return // Explicit empty string check

    try {
      const response: Response = await fetch(`/risks/source_causes_for_source/${selectedSourceId}`)
      const data: { source_causes: Array<{ id: number, name: string }> } = await response.json()

      const sourceCauseSelect = this.sourceCauseTarget
      sourceCauseSelect.innerHTML = '' // Clear options

      data.source_causes?.forEach((cause) => {
        const option = document.createElement('option')
        option.value = cause.id.toString() // Ensure id is a string for the value
        option.textContent = cause.name
        sourceCauseSelect.appendChild(option)
      })
    } catch (error) {
      console.error('Error fetching source causes:', error)
    }
  }
}
