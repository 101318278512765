import FacilitiesSelectController, { FacilityTomOption } from './facilities_select_controller'
import { escape_html } from 'tom-select/src/utils'

const openFacilityIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="h-4 w-4 text-primary dark:text-primary-dark shrink-0">' +
  '<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path>' +
  '</svg>'

export default class extends FacilitiesSelectController {
  static values = {
    url: { type: String },
    organizationId: String
  }

  declare organizationIdValue: string

  settings = {
    searchField: ['name', 'licenseNumber'],
    plugins: ['no_close', 'checkbox_options', 'remove_button', 'virtual_scroll'],
    dropdownParent: '#searchbar-parent'
  }

  connect (): void {
    super.connect()

    this.plugin.control_input.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.plugin.control_input.focus({ preventScroll: true })
    })

    this.selectDropdownPosition = () => {}

    this.plugin.onBlur = () => {}
  }

  buildUrl (query: string): string {
    if (this.organizationIdValue === '') {
      return super.buildUrl(query)
    } else {
      return `/facilities?page=${this.currentPage}&q[organization_id_eq]=${this.organizationIdValue}` +
      (query === '' ? '' : `&q[name_cont]=${encodeURIComponent(query)}`)
    }
  }

  renderOption (data: FacilityTomOption, escape: typeof escape_html): HTMLElement {
    if (data.id === 'unassigned') {
      const optionElement = document.createElement('div')

      optionElement.innerHTML =
        '<div class="option-link"><div class="flex items-center space-x-1.5"><p>' +
        `${data.name}</p></div></div>`

      return optionElement
    }

    return super.renderOption(data, escape)
  }

  defaultOption (data: FacilityTomOption): void {
    data.unshift({ id: 'unassigned', name: 'Without Entity' })
  }

  renderItem (data: FacilityTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')

    if (data.id === 'unassigned') {
      optionElement.innerHTML =
        '<div data-user-filter-target="facilities" class="flex flex-row items-center w-full">' +
        `<p class="px-1">${escape(data.name)}</p></div>`
    } else {
      optionElement.innerHTML =
        '<div data-user-filter-target="facilities" class="item-max flex flex-row items-center">' +
        `<a class="h-4 w-4" href="${escape(data.url)}" target="_blank">${openFacilityIcon}</a>` +
        `<p class="px-1">${escape(data.name)}</p></div>`

      const facilityAnchor = optionElement.querySelector('a') as HTMLAnchorElement
      facilityAnchor.addEventListener('click', (event) => {
        event.stopPropagation()
      })
    }

    return optionElement
  }
}
