import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit']

  declare readonly submitTargets: HTMLInputElement[]

  enable (): void {
    this.submitTargets.forEach(target => {
      target.disabled = false
    })
  }
}
