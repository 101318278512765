import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets: string[] = ['_form', 'probability', 'impact', 'score', 'riskCategory', 'riskScore', 'riskColon']

  declare readonly impactTarget: HTMLInputElement
  declare readonly probabilityTarget: HTMLInputElement
  declare readonly scoreTarget: HTMLElement
  declare readonly riskCategoryTarget: HTMLElement
  declare readonly riskScoreTarget: HTMLElement
  declare readonly riskColonTarget: HTMLElement
  riskId: number | null = null

  async connect (): Promise<void> {
    const pathSegments = window.location.pathname.split('/')
    this.riskId = Number(pathSegments[2])
    await this.updateScore()
  }

  async updateScore (): Promise<void> {
    const impact: number = parseInt(this.impactTarget.value)
    const probability: number = parseInt(this.probabilityTarget.value)
    const score: number = impact * probability

    this.scoreTarget.textContent = isNaN(score) ? '' : score.toString()

    if (score === 0) {
      this.riskCategoryTarget.textContent = ''
      this.riskScoreTarget.className = `${this.getBaseClasses()} bg-transparent`
    } else {
      await this.fetchRiskColor(impact, probability)
    }
  }

  async fetchRiskColor (impact: number, probability: number): Promise<void> {
    try {
      if (this.riskId === null) {
        throw new Error('Risk ID is null')
      }

      const response = await fetch(`/risks/${this.riskId}/risk_assessments/risk_color?impact=${impact}&probability=${probability}`)

      if (!response.ok) {
        throw new Error('Failed to fetch risk color data')
      }

      const data: { severity_level?: string, color?: string } = await response.json()

      if (typeof data.severity_level === 'string' && typeof data.color === 'string') {
        this.riskCategoryTarget.textContent = data.severity_level
        this.riskColonTarget.textContent = ':'

        const colorClass = this.getColorClass(data.color)

        this.riskScoreTarget.className = `${this.getBaseClasses()} ${colorClass}`
      } else {
        this.resetRiskInfo()
      }
    } catch (error) {
      console.error('Error fetching risk color data:', error)
      this.resetRiskInfo()
    }
  }

  getColorClass (color: string): string {
    switch (color.toLowerCase()) {
      case 'teal':
        return 'bg-teal-100 dark:bg-teal-800'
      case 'green':
        return 'bg-green-100 dark:bg-green-800'
      case 'light-amber':
        return 'bg-amber-100 dark:bg-amber-800'
      case 'dark-amber':
        return 'bg-orange-100 dark:bg-orange-800'
      case 'red':
        return 'bg-red-50 dark:bg-red-950'
      default:
        return 'bg-transparent'
    }
  }

  resetRiskInfo (): void {
    this.riskCategoryTarget.textContent = ''
    this.riskColonTarget.textContent = ''
    this.riskScoreTarget.className = `${this.getBaseClasses()} bg-transparent`
  }

  getBaseClasses (): string {
    return 'rounded-md px-4 py-3'
  }
}
