import { Controller } from '@hotwired/stimulus'

// Simplified copy of https://github.com/excid3/tailwindcss-stimulus-components/blob/main/src/tabs.js
// Removed anchor and scroll features
export default class extends Controller {
  static classes = ['activeTab', 'inactiveTab']
  static targets = ['tab', 'panel', 'select']
  static values = {
    index: 0
  }

  declare indexValue: number
  declare activeTabClasses: string[]
  declare hasActiveTabClass: boolean
  declare inactiveTabClasses: string[]
  declare hasInactiveTabClass: boolean
  declare tabTargets: HTMLElement[]
  declare panelTargets: HTMLElement[]
  declare selectTarget: HTMLSelectElement
  declare hasSelectTarget: boolean

  connect (): void {
    this.showTab()
  }

  // Changes to the clicked tab
  change (event: any): void {
    if (event.currentTarget.tagName === 'SELECT') {
      this.indexValue = event.currentTarget.selectedIndex

    // If target specifies an index, use that
    } else if (event.currentTarget.dataset.index !== undefined) {
      this.indexValue = event.currentTarget.dataset.index

    // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id !== undefined) {
      this.indexValue = this.tabTargets.findIndex((tab) => tab.id === event.currentTarget.dataset.id)

    // Otherwise, use the index of the current target
    } else {
      this.indexValue = this.tabTargets.indexOf(event.currentTarget)
    }
  }

  nextTab (): void {
    this.indexValue = Math.min(this.indexValue + 1, this.tabsCount - 1)
  }

  previousTab (): void {
    this.indexValue = Math.max(this.indexValue - 1, 0)
  }

  firstTab (): void {
    this.indexValue = 0
  }

  lastTab (): void {
    this.indexValue = this.tabsCount - 1
  }

  indexValueChanged (): void {
    this.showTab()
  }

  showTab (): void {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets[index]

      if (index === this.indexValue) {
        panel.classList.remove('hidden')
        tab.ariaSelected = 'true'
        tab.dataset.active = 'true'
        if (this.hasInactiveTabClass) tab?.classList?.remove(...this.inactiveTabClasses)
        if (this.hasActiveTabClass) tab?.classList?.add(...this.activeTabClasses)
      } else {
        panel.classList.add('hidden')
        tab.ariaSelected = null
        delete tab.dataset.active
        if (this.hasActiveTabClass) tab?.classList?.remove(...this.activeTabClasses)
        if (this.hasInactiveTabClass) tab?.classList?.add(...this.inactiveTabClasses)
      }
    })

    if (this.hasSelectTarget) {
      this.selectTarget.selectedIndex = this.indexValue
    }
  }

  get tabsCount (): number {
    return this.tabTargets.length
  }
}
