import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['counter', 'checkbox']

  declare readonly counterTarget: HTMLElement
  declare readonly checkboxTargets: HTMLInputElement[]

  connect (): void {
    this.updateCounter()
    document.addEventListener('count-checkboxes:update', this.updateCounter.bind(this))
  }

  disconnect (): void {
    document.removeEventListener('count-checkboxes:update', this.updateCounter.bind(this))
  }

  updateCounter (): void {
    const checkedCount = this.checkboxTargets.filter(checkbox => checkbox.checked).length
    this.counterTarget.innerText = checkedCount.toString()
    this.counterTarget.classList.toggle('hidden', checkedCount === 0)
  }
}
