import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab']
  static classes = ['active', 'inactive']

  declare readonly activeClasses: string[]
  declare readonly inactiveClasses: string[]
  declare readonly tabTargets: [HTMLElement, ...HTMLElement[]]
  declare activeTab: HTMLElement

  connect (): void {
    const selectors = `a[href="${location.pathname}${location.hash}"]`
    const tab = this.tabTargets.find(tab => tab.querySelector(selectors)) ?? this.tabTargets[0]

    this.activate(tab)
  }

  touch (e: Event): void {
    this.activate(this.tabTargets.find(tab => tab.contains(e.target as HTMLElement)) as HTMLElement)
  }

  activate (tab: HTMLElement): void {
    if (this.activeTab != null) {
      this.activeTab.classList.remove(...this.activeClasses)
      this.activeTab.classList.add(...this.inactiveClasses)
    }
    this.activeTab = tab
    this.activeTab.classList.add(...this.activeClasses)
    this.activeTab.classList.remove(...this.inactiveClasses)
  }

  reset (): void {
    this.activate(this.tabTargets[0])
  }
}
