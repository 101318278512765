import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLLinkElement> {
  scroll (): void {
    const id = this.element.getAttribute('href')
    if (id === null) {
      return
    }

    const target = document.querySelector(id)
    if (target === null) {
      return
    }

    target.scrollIntoView({ behavior: 'smooth' })
  }
}
